//Bootstrap JS
import * as bootstrap from 'bootstrap';
//gLightbox
import GLightbox from 'glightbox';
import {setupRecaptcha} from "./recaptcha";

//Jquery
import "./jquery-global.js";
import $ from "jquery";

import 'jquery-validation';
import 'jquery-validation-unobtrusive';


GLightbox({
    selector: ".glightbox",
});

setupRecaptcha();
